// extracted by mini-css-extract-plugin
export var badge = "ltd-offers-module--badge--99f54";
export var grid = "ltd-offers-module--grid--f67e5";
export var line = "ltd-offers-module--line--2b6a8";
export var overwrite = "ltd-offers-module--overwrite--2a562";
export var plus = "ltd-offers-module--plus--1b944";
export var point = "ltd-offers-module--point--9fc36";
export var pointContainer = "ltd-offers-module--point-container--76490";
export var pointDescription = "ltd-offers-module--point-description--1336e";
export var pointTitle = "ltd-offers-module--point-title--c6bcc";
export var pointsWrapper = "ltd-offers-module--points-wrapper--7daf3";
export var popular = "ltd-offers-module--popular--239e9";
export var section = "ltd-offers-module--section--ce968";
export var spacer = "ltd-offers-module--spacer--0cab2";
export var subtitle = "ltd-offers-module--subtitle--e8650";
export var title = "ltd-offers-module--title--e6f8d";
export var variant = "ltd-offers-module--variant--c1a60";
export var wrapper = "ltd-offers-module--wrapper--f84df";