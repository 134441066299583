import React from 'react';

import {
    overwrite,
    section as sectionClass,
    grid,
    wrapper,
    variant,
    popular,
    title,
    subtitle,
    pointsWrapper,
    pointContainer,
    point,
    pointTitle,
    pointDescription,
    spacer,
    line,
    plus,
    badge,
} from './ltd-offers.module.scss';

import CheckPoint from '../../assets/images/svg/check-point.svg';

import { ISection } from '../../models/section.model';

import Section from '../hoc/section';
import useTranslations from '../../hooks/use-translations';
import Markdown from '../hoc/markdown';

interface ILtdOffersProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: ISection;
}

export default function LtdOffers({ className, section }: ILtdOffersProps) {
    const { sectionId, css, style } = section;

    const t = useTranslations('LtdOffers');

    return (
        <Section
            sectionId={sectionId}
            className={`${sectionClass} ${className} ${overwrite}`}
            classes={{
                container: grid,
            }}
            css={css}
            style={style}
        >
            <div className={wrapper}>
                <div className={variant}>
                    <p className={subtitle}>{t.simple.subtitle}</p>
                    <p className={title}>{t.simple.title}</p>
                    <div className={pointsWrapper}>
                        <div className={pointContainer}>
                            <CheckPoint className={point} />
                            <div>
                                <p className={pointTitle}>{t.simple['1'].title}</p>
                                <p className={pointDescription}>{t.simple['1'].description}</p>
                            </div>
                        </div>
                        <div className={pointContainer}>
                            <CheckPoint className={point} />
                            <div>
                                <p className={pointTitle}>{t.simple['2'].title}</p>
                                <p className={pointDescription}>{t.simple['2'].description}</p>
                            </div>
                        </div>
                        <div className={pointContainer}>
                            <CheckPoint className={point} />
                            <div>
                                <p className={pointTitle}>{t.simple['3'].title}</p>
                                <p className={pointDescription}>{t.simple['3'].description}</p>
                            </div>
                        </div>
                        <div className={pointContainer}>
                            <CheckPoint className={point} />
                            <div>
                                <p className={pointTitle}>{t.simple['4'].title}</p>
                                <p className={pointDescription}>{t.simple['4'].description}</p>
                            </div>
                        </div>
                        <div className={pointContainer}>
                            <CheckPoint className={point} />
                            <div>
                                <p className={pointTitle}>{t.simple['5'].title}</p>
                                <p className={pointDescription}>{t.simple['5'].description}</p>
                            </div>
                        </div>
                        <div className={pointContainer}>
                            <CheckPoint className={point} />
                            <div>
                                <p className={pointTitle}>{t.simple['6'].title}</p>
                                <p className={pointDescription}>{t.simple['6'].description}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${variant} ${popular}`}>
                    <span className={badge}>{t.mid.popular}</span>
                    <p className={subtitle}>{t.mid.subtitle}</p>
                    <p className={title}>{t.mid.title}</p>
                    <div className={pointsWrapper}>
                        <div className={pointContainer}>
                            <CheckPoint className={point} />
                            <Markdown className={pointTitle}>{t.mid.included}</Markdown>
                        </div>
                        <div className={spacer}>
                            <span className={line} />
                            <span className={plus}>+</span>
                            <span className={line} />
                        </div>
                        <div className={pointContainer}>
                            <CheckPoint className={point} />
                            <div>
                                <p className={pointTitle}>{t.mid['1'].title}</p>
                                <p className={pointDescription}>{t.mid['1'].description}</p>
                            </div>
                        </div>
                        <div className={pointContainer}>
                            <CheckPoint className={point} />
                            <div>
                                <p className={pointTitle}>{t.mid['2'].title}</p>
                                <p className={pointDescription}>{t.mid['2'].description}</p>
                            </div>
                        </div>
                        <div className={pointContainer}>
                            <CheckPoint className={point} />
                            <div>
                                <p className={pointTitle}>{t.mid['3'].title}</p>
                                <p className={pointDescription}>{t.mid['3'].description}</p>
                            </div>
                        </div>
                        <div className={pointContainer}>
                            <CheckPoint className={point} />
                            <div>
                                <p className={pointTitle}>{t.mid['4'].title}</p>
                                <p className={pointDescription}>{t.mid['4'].description}</p>
                            </div>
                        </div>
                        <div className={pointContainer}>
                            <CheckPoint className={point} />
                            <div>
                                <p className={pointTitle}>{t.mid['5'].title}</p>
                                <p className={pointDescription}>{t.mid['5'].description}</p>
                            </div>
                        </div>
                        <div className={pointContainer}>
                            <CheckPoint className={point} />
                            <div>
                                <p className={pointTitle}>{t.mid['6'].title}</p>
                                <p className={pointDescription}>{t.mid['6'].description}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={variant}>
                    <p className={subtitle}>{t.full.subtitle}</p>
                    <p className={title}>{t.full.title}</p>
                    <div className={pointsWrapper}>
                        <div className={pointContainer}>
                            <CheckPoint className={point} />
                            <Markdown className={pointTitle}>{t.full.included}</Markdown>
                        </div>
                        <div className={spacer}>
                            <span className={line} />
                            <span className={plus}>+</span>
                            <span className={line} />
                        </div>
                        <div className={pointContainer}>
                            <CheckPoint className={point} />
                            <div>
                                <p className={pointTitle}>{t.full['1'].title}</p>
                                <p className={pointDescription}>{t.full['1'].description}</p>
                            </div>
                        </div>
                        <div className={pointContainer}>
                            <CheckPoint className={point} />
                            <div>
                                <p className={pointTitle}>{t.full['2'].title}</p>
                                <p className={pointDescription}>{t.full['2'].description}</p>
                            </div>
                        </div>
                        <div className={pointContainer}>
                            <CheckPoint className={point} />
                            <div>
                                <p className={pointTitle}>{t.full['3'].title}</p>
                                <p className={pointDescription}>{t.full['3'].description}</p>
                            </div>
                        </div>
                        <div className={pointContainer}>
                            <CheckPoint className={point} />
                            <div>
                                <p className={pointTitle}>{t.full['4'].title}</p>
                                <p className={pointDescription}>{t.full['4'].description}</p>
                            </div>
                        </div>
                        <div className={pointContainer}>
                            <CheckPoint className={point} />
                            <div>
                                <p className={pointTitle}>{t.full['5'].title}</p>
                                <p className={pointDescription}>{t.full['5'].description}</p>
                            </div>
                        </div>
                        <div className={pointContainer}>
                            <CheckPoint className={point} />
                            <div>
                                <p className={pointTitle}>{t.full['6'].title}</p>
                                <p className={pointDescription}>{t.full['6'].description}</p>
                            </div>
                        </div>
                        <div className={pointContainer}>
                            <CheckPoint className={point} />
                            <div>
                                <p className={pointTitle}>{t.full['7'].title}</p>
                                <p className={pointDescription}>{t.full['7'].description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    );
}
