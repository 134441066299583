// extracted by mini-css-extract-plugin
export var grid = "ltd-services2-module--grid--e1b05";
export var imageContainer = "ltd-services2-module--image-container--10667";
export var section = "ltd-services2-module--section--6316e";
export var sectionImage = "ltd-services2-module--section-image--0809f";
export var stepElement = "ltd-services2-module--step-element--65af5";
export var stepNumber = "ltd-services2-module--step-number--21cc8";
export var stepText = "ltd-services2-module--step-text--445b7";
export var stepsContainer = "ltd-services2-module--steps-container--c5dd2";
export var subtitleText = "ltd-services2-module--subtitle-text--e70c7";
export var titleText = "ltd-services2-module--title-text--d3bd6";
export var wrapper = "ltd-services2-module--wrapper--4b1f8";