import React, { useRef } from 'react';

import {
    section as sectionClass,
    grid,
    wrapper,
    titleText,
    subtitleText,
    stepsContainer,
    stepElement,
    stepNumber,
    stepText,
    imageContainer,
    sectionImage,
} from './ltd-services2.module.scss';
import { ISection } from '../../models/section.model';

import Section from '../hoc/section';
import { IMedia } from '@alterpage/gatsby-source-alterpress';
import { usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';
import { Image } from '@alterpage/gatsby-plugin-image';

interface ILtdServices2Section extends ISection {
    content: ISection['content'] & {
        images: {
            media: IMedia[];
            title: {
                [k: string]: string;
            };
        }[];
    };
}

interface ILtdServices2Props {
    className?: string;
    TitleTag?: React.ElementType;
    section: ILtdServices2Section;
}

export default function LtdServices2({ className, section }: ILtdServices2Props) {
    const { sectionId, content, css, style } = section;
    const { texts, images, media } = content;
    const [title, subtitle] = texts;
    const sectionRef = useRef(null);
    const { locale } = usePageContext();

    return (
        <Section
            sectionId={sectionId}
            className={`${sectionClass} ${className}`}
            classes={{
                container: grid,
            }}
            css={css}
            style={style}
            ref={sectionRef}
        >
            <div className={wrapper}>
                <h1 className={titleText}>{title}</h1>
                <p className={subtitleText}>{subtitle}</p>
                <div className={stepsContainer}>
                    {images.map((image, i) => {
                        return (
                            <div className={stepElement} key={`step-${i}`}>
                                <Image className={imageContainer} media={image.media} />
                                <p className={stepNumber}>0{i + 1}.</p>
                                <p className={stepText}>{image.title[locale]}</p>
                            </div>
                        );
                    })}
                </div>
                {media && media.length > 0 && <Image className={sectionImage} media={[media[0]]} />}
            </div>
        </Section>
    );
}
